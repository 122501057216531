import React from 'react'
import { Link } from 'gatsby'
import Title from 'components/common/Text/StyledText'
import {
  FooterWrapper,
  FlexColumnWrapper,
  SocialMediaIconsContainer,
  StyledHr,
  FlexWrapper,
  FlexItem,
} from './styled-components'

import SuadaLogo from 'images/svg/suadaLogoOnDark.svg'
import AppStoreIcon from 'images/svg/app-store.svg'
import PlayStoreIcon from 'images/svg/play-store.svg'
import FacebookIcon from 'images/svg/facebook.svg'
import TwitterIcon from 'images/svg/twitter.svg'
import YoutubeIcon from 'images/svg/youtube.svg'
import InstagramIcon from 'images/svg/instagram.svg'
import TumblrIcon from 'images/svg/tumblr.svg'

import { StyledButton } from 'components/common'

const StyledFooter = () => {
  return (
    <FooterWrapper>
      {/* <FlexColumnWrapper>
        <Title textColor="white">Find us on social media</Title>
        <SocialMediaIconsContainer>
          <Link to="facebook.com">
            <FacebookIcon />
          </Link>
          <Link to="twitter.com">
            <TwitterIcon />
          </Link>
          <Link to="youtube.com">
            <YoutubeIcon />
          </Link>
          <Link to="instagram.com">
            <InstagramIcon />
          </Link>
          <Link to="tumbrl.com">
            <TumblrIcon />
          </Link>
        </SocialMediaIconsContainer>
      </FlexColumnWrapper>
      <StyledHr /> */}
      <FlexWrapper>
        <FlexItem className="first-dialog">
          <SuadaLogo />
          <Title
            textColor="white"
            fontType="regular"
            fontWeight={400}
            fontSize="14px"
            textAlign="left"
            className="footer-logo"
          >
            Social learning platform to deliver high-quality blended learning
            with human connection
          </Title>
        </FlexItem>
        <FlexItem>
          <h5>Learning</h5>
          <ul className="list">
            <Link to="/educators">
              <li>For Educators</li>
            </Link>
            <Link to="/businesses">
              <li>For Businesses</li>
            </Link>
            <Link to="/learners">
              <li>For Learners</li>
            </Link>
          </ul>
        </FlexItem>
        <FlexItem>
          <h5>Case Studies</h5>
          <ul className="list">
            <Link to="/CaseStudies/Bsi">
              <li>BSI Success Story</li>
            </Link>
            <Link to="/CaseStudies/Cimspa">
              <li>Cimpspa Success Story</li>
            </Link>
            <Link to="/CaseStudies/Coutts">
              <li>Coutts Success Story</li>
            </Link>
            <Link to="/CaseStudies/PremierGlobal">
              <li>Premiere Global Success Story</li>
            </Link>
          </ul>
        </FlexItem>
        <FlexItem>
          <h5>Legal</h5>
          <ul className="list">
            <Link to="/contact-us">
              <li>Contact Us</li>
            </Link>
            <Link to="/terms-and-conditions">
              <li>Terms and Conditions</li>
            </Link>
            <Link to="/privacy-policy">
              <li>Privacy Policy</li>
            </Link>
            <Link to="/cookie-policy">
              <li>Cookie Policy</li>
            </Link>
            <Link to="/GDPR">
              <li>GDPR</li>
            </Link>
          </ul>
        </FlexItem>
        <FlexItem>
          <h5>Download App</h5>
          <StyledButton
            to={
              'https://play.google.com/store/apps/details?id=com.suada&hl=en&gl=US'
            }
            color="black"
          >
            <PlayStoreIcon />
            Play Store
          </StyledButton>
          <StyledButton
            to={'https://apps.apple.com/us/app/suada/id1526722110'}
            color="black"
          >
            <AppStoreIcon />
            App Store
          </StyledButton>
        </FlexItem>
      </FlexWrapper>
      <StyledHr />
      <FlexItem>
        <p className="copyright">SUADA 2022 @ all rights reserved</p>
      </FlexItem>
    </FooterWrapper>
  )
}

export default StyledFooter
