import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 0 6%;
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 80px 165px 60px 165px;
  gap: 20px;
  .first-dialog {
    width: 30%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 60px 0px 40px 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    padding: 60px 0;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    div {
      text-align: center;
    }

    .first-dialog {
      width: 100%;
    }
  }
`

export const FlexItem = styled.div`
  font-size: 16px;
  color: white;
  font-family: 'Poppins-bold';
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    align-items: center;

    .footer-logo {
      text-align: center;
    }
  }

  .list {
    li {
      font-family: 'Poppins';
      margin-bottom: 10px;
      font-weight: 400;
    }

    .inner {
      margin-left: 10px;
    }
  }

  .copyright {
    margin: 16px 0;
    font-weight: 400;
    font-family: 'Poppins';
  }
`

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 56px 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    .title {
      text-align: center;
      font-size: 16px;
      margin-bottom: 14px;
    }
  }
`

export const SocialMediaIconsContainer = styled.div`
  display: flex;
  justify-content: flex;
  align-items: center;
  gap: 24px;
  margin-top: 4px;
`

export const StyledHr = styled.hr`
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 1px;
  border: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.wl}) {
    width: calc(100% - 350px);
  }
`
